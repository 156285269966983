.Header--MainContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.Header--Logo{
    width: 76px;
}
.Header--TitleContanier{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.Header--LogoTitleContainer{
    display: flex;
}
.Header--logout_button{
    color: #5AC6C3;
    cursor: pointer;
    margin-right: 20px;
}
.Header--ActionButtonsContainer{
    display: flex;
    align-items: center;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }