.LoginPage--MainContainer{
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: space-evenly;
}
.LoginPage--LogoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.LoginPage--Logo{
    width: 300px;
}
.LoginPage-LoginInputsContainer{
    display: flex;
    flex-direction: column;
}
.LoginPage-InputContainer{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.LoginPage-LoginButtonContainer{
    display: flex;
    justify-content: center;
}