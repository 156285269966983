.IceMachineSummeryChip--MainContainer{
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 250px;
    background-color: #272827;
    margin: 10px;
    padding: 10px;
    border: solid 1px white;
    border-radius: 10px;
}

.IceMachineSummeryChip--HeaderContainer{
    display: flex;
    border-bottom: solid 1px white;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

.IceMachineSummaryChip--MachineNameStatusContainer{
    display: flex;
    align-items: center;
}

.IceMachineSummaryChip--Status_Active{
    color: #36CF1D;
    font-size: 15px;
    cursor: pointer;
    margin-left: 2px;
}
.IceMachineSummaryChip--Status_Inactive{
    color: #CF1D1D;
    font-size: 15px;
    cursor: pointer;
    margin-left: 2px;
}

.IceMachineSummaryChip--ActionButtonsContainer{
    display: flex;
}

.IceMachineSummeryChip--StatsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.IceMachineSummeryChip--SingleStatContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.IceMachineSummeryChip--LastReportsContainer{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}