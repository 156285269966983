body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section--name_primary{
  color: #5AC6C3;
  font-size: 32px;
}
.section--name_secondary{
  color: #ffffff;
  font-size: 32px;
}
.header--title_primary{
  color: #5AC6C3;
  font-size: 20px;
}
.header--title_secondary{
  color: #ffffff;
  font-size: 20px;
}
.header--subtitle_primary{
  color: #5AC6C3;
  font-size: 16px;
}
.header--subtitle_secondary{
  color: #ffffff;
  font-size: 16px;
}
.input--label--primary{
  color: #5AC6C3;
  font-size: 16px;
}
.button--primary{
  background-color: #5AC6C3;
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 10px;
}

.button--secondary{
  background-color: #ffffff;
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 10px;
}

.icon--primary{
  color: #5AC6C3;
  cursor: pointer;
}

.icon--requesting-data{
  color: #7b1111;
  cursor: pointer;
}
