/* Toast.css */

.toast {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 20px;
    padding: 10px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin: 0px 10px;
  }

  .error{
    background-color: #df6e6d;
    color: #fff;
  }
  .warning{
    background-color: #d2df6d;
    color: #fff;
  }
  .info{
    background-color: #6d9fdf;
    color: #fff;
  }
  .done{
    background-color: #6ddfc1;
    color: #fff;
  }
  
  .toast.show {
    opacity: 1;
    z-index: 300;
  }
  
  .toast.hide {
    opacity: 0;
  }

  .toast-icon{
    margin-right: 10px;
  }

  /*RESPONSIVENESS STARTS HERE ;)*/
/*THESE ARE GENERAL SIZES*/
/*------------------------------------------------------------------*/
/*X-Small                                                           */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (max-width: 575px) and (orientation:portrait) {
    /*PORTRAIT*/
    .toast {
        padding: 5px 5px;
      }
}
@media only screen and (max-height: 575px) and (orientation:landscape) {
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Small                                                             */
/*------------------------------------------------------------------*/
/*Portrait*/
@media only screen and (min-width: 576px) and (max-width: 767px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 576px) and (max-height: 767px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Medium                                                            */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 768px) and (max-width: 991px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 768px) and (max-height: 991px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Large                                                             */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 992px) and (max-width: 1199px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 992px) and (max-height: 1199px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra large                                                       */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1200px) and (max-height: 1399px) and (orientation:landscape){
    /*LANDSCAPE*/
}

/*------------------------------------------------------------------*/
/*Extra extra large                                                      */
/*------------------------------------------------------------------*/
/*PORTRAIT*/
@media only screen and (min-width: 1400px) and (max-width: 5000px) and (orientation:portrait){
    /*PORTRAIT*/
}
/*LANDSCAPE*/
@media only screen and (min-height: 1400px) and (max-height: 5000px) and (orientation:landscape){
    /*LANDSCAPE*/
}