.IceMachineConfigView--MainContainer{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
}

.IceMachineConfigView--InputContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}