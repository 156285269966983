.DashboardPage--MainContainer{
    display: flex;
    background-color: black;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
}
.Dashboard--Title{
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    border-bottom: solid 1px white;
}
.DasboardPage--IceMachines_Container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}